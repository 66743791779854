.mixed-carousel {
  position:relative;
  margin-bottom: 40px;
  z-index:2;
  
  .loader-container.spinner {
      background:none;
      background-color:none;
  }
  .mixed-carousel-title {
    font-size: 17px;
  }
  .slick-arrow {
    transform:none!important;
    transition: all 0.2s ease-out;
    z-index:1;
    &.slick-prev {
      border-radius: 0px 4px 4px 0px;
      left: -60px;
    }
    &.slick-next {
      border-radius: 4px 0px 0px 4px;
      right: -60px;
    }
    background-color: rgba(0,0,0,0);
    svg {
      opacity:0.2;
      transition: all 0.2s ease-out;
      transition-timing-function: cubic-bezier(.5,.1,0,1);
      fill: white;
      color: white;
      &:hover {
        fill: $radivision-blue;
        color: $radivision-blue;
      }
    }
  }
  .item-card-actions {
    padding: 0 19px;
    margin-top: 13px;
    display: none;
    align-items: center;
    width: 100%;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #525252;
      cursor: pointer;
      border-radius: 17px;
      flex: 1;
      margin: 0px 4px;
      padding: 7px;
      max-width: 60px;

      svg {
        height: 15px;
        width: 15px;
        fill: #fff;
      }

      &.play-btn {
        background-color: $radivision-blue;
      }

      &.saved {
        background-color: #fff;

        svg {
          fill: $radivision-blue;
        }
      }
      
      @include media-breakpoint-down(md) {
        padding: 7px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        max-width: 32px;
        box-sizing: border-box;
      }
      @include media-breakpoint-down(sm) {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        max-width: 36px;
        box-sizing: border-box;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0px 5px;
      margin-top: 7px;
      box-sizing: border-box;
    }
  }
  &:hover {
    .slick-arrow {
      background-color: rgba(0,0,0,0.6);
      svg {
        opacity:1;
        transform:scale(1.3);
      }
    }
  }
  .mixed-carousel-header {
    margin:0px 0px 10px 70px;
    display: flex;
    position:relative;
    h3 {
      font-size:18px;
      font-weight: 800;
      letter-spacing: 0.075rem !important;
      font-family: "MuliExtraBold";
      a {
        display:none;
        font-size:13px;
        display:none;
        margin-left:20px;
        font-weight:normal;
        color:#19acf7;
      }
    }
    p {
      font-size:15px!important;
    }
  }
  .slick-slide {
    &:hover {
      z-index:2;
    }
  }
  .slick-slider {
    position:relative;
  }
  .slick-track {
    display: flex;
  }
  .item-card-container {
    position: relative;
    box-sizing:border-box;
    margin:0px 30px;
    cursor: pointer;
  }
  .item-card {
    color: #fff;
    font-size: 36px;
    border-radius: 4px;
    transition: transform 0.1s linear;
    transform: scale(1) translateY(0);
    position: relative;
    text-align: center;
    width:100%;
    &.BookStory {
      .item-card-text {
        padding:10px 0px;
        h3 {
          text-align:center;
        }
      }
    }
    &.PodcastStory, &.OriginalContentStory {
      .popover {
        .item-preview-container {
          padding-bottom: 56.25%;
        }
      }
    }
    .popover {
      background-color:#212121;
      position:absolute;
      left:0px;
      right:0px;
      border-radius: 4px;
      transform-origin: top center;
      overflow:hidden;
      transition: all 0.2s cubic-bezier(0.5, 0.1, 0, 1);
      transform: scale(1) translateY(0);
      opacity: 0;
      &:hover {
        transform: scale(1.2) translateY(-30px);
        opacity: 1;
      }
      z-index:11;
      .item-preview-container {
        position:relative;
        background-color:#272727;
        border-radius: 4px 4px 0px 0px;
        .item-video {
          transition: opacity 0.8s ease-in;
          z-index:1;
          position:absolute;
          right:0;
          left:0;
          top:0;
          bottom:0;
        }
        .item-preview {
          transition: opacity 0.8s ease-in;
          border-radius: 4px 4px 0px 0px;
          z-index:3;
          position:absolute;
          right:0;
          left:0;
          top:0;
          bottom:0;
        }
      }
      .item-card-actions {
        display: flex;
      }
      .item-card-text {
        padding: 10px 10px;
        position:relative;
        z-index:2;
        h3 {
          transform:scale(0.85);
          max-height:unset;
          min-height:unset;
          &::after {
            display:none;
          }
        }
      }
    }
    .item-card-text {
      font-size:14px;
      color:#fff;
      text-align:left;
      padding: 10px 0px 10px 10px;
      transition: transform 0.1s linear;
      h3 {
        font-size: 14px;
        line-height: 20px;
        min-height: 58px;
        max-height: 58px;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;
        padding:0px;
        &::after {
          display: block;
          content: "";
          position: absolute;
          background-image: linear-gradient(
            90deg,
            rgba(22, 22, 22, 0),
            $dark-mode-bg
          );
          height: 15px;
          bottom: 0;
          right: 0;
          width: 70%;
        }
      }
    }
    .item-card-title {
      margin:20px 0px;
      height:40px;
      display: flex;
      justify-content: space-between;
    }
  }
  .Person, .Entity {
    .popover {
      .item-preview-container {
        border-radius:50%;
      }
    }
  }
  .first-active {
    .item-card {
      .popover {
        transform-origin: top left;
      }
    }
  }
  .last-active {
    .item-card {
      .popover {
        transform-origin: top right;
      }
    }
  }
  .item-preview-container {
    overflow:hidden;
    border-radius: 4px;
    overflow:hidden;
  }
  .item-preview {
    transition: opacity 0.8s ease-in;
    transition-delay: 1s;
  }
  .slick-current {
    .item-video {
      box-shadow:1px 1px 20px 1px rgba(0,0,0,0.5);
    }
  }
  .item-preview-container {
    padding-bottom: 100%;
    height: 0px;
    position:relative;
    .item-video {
      border-radius: 4px;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-color:#171717;
      flex:1;
      overflow:hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index:1;
    }
    .item-preview {
      overflow:hidden;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index:2;
    }
  }
  .PodcastStory, .OriginalContentStory {
    .item-preview-container {
      padding-bottom: 50%;
    }
    .item-preview {
    }
  }
  .Person, .Entity {
    .item-preview-container {
      border-radius:50%;
    }
  }
  @media (max-width: 700px) {
    .content {
      width: 70%;
    }
  }
  .slick-slide .image {
    padding: 10px;
  }
  .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
    max-width: 80%;
  }
  .slick-slide img.slick-loading {
    border: 0;
  }
  .slick-slider {
    position:relative;
    margin:0px 60px;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    width: 100%;
    height: 100%;
  }
  .slick-thumb li.slick-active img {
  }
  .slick-vertical .slick-slide {
    height: 280px;
  }
  .slick-arrow {
    position: absolute;
    width: 40px;
    display: flex;
    &.slick-disabled {
      svg {
        fill: #555555;
      }
    }
  }
  .slick-arrow:hover {
  }
  .slick-arrow:focus {
  }

  .button {
    padding: 10px 20px;
    margin: 0px 20px;
    border: none;
    color: white;
    font-size: 20px;
    border-radius: 5px;
    min-height: 45px;
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    border: none;
    outline: 0;
    z-index: 1;
  }
  .slick-arrow svg {
    color: #ffffff;
    width: 50px;
    height: 50px;
  }
  .slick-arrow:before,
  [dir="rtl"] .slick-prev:before {
    content: "";
    display: none;
  }
  .slick-slide,
  .item-card {
    outline: none;
  }

    .item-preview-container {
      background: #272727;
    }
    .pitch-carousel-header {
      display: flex;
      flex-flow: column;
      max-width: none;
      position:relative;
      margin: 20px 40px;
      h3 {
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom: 10px;
        a {
          display:none;
          font-size:13px;
          margin-left:20px;
          flex-wrap: nowrap;
          font-weight:normal;
          color:#19acf7;
          text-transform: uppercase;
          cursor:pointer;
          white-space:nowrap;
          svg {
            display: inline;
          }
        }
      }
      p {
        font-size:13px;
      }
    }
    .item-card {
      font-size: 24px;
      .item-card-title {
        flex-flow: column;
        margin:20px 0px;
        height:auto;
      }
    }
    .slick-arrow {
      width: 40px;
      height: 40px;
      display: flex;
    }
    .slick-prev {
      left: 0px;
      display:flex;
      justify-content:flex-start;
    }
    .slick-next {
      right: 0px;
      display:flex;
      justify-content:flex-end;
    }
    .slick-arrow svg {
      width: 20px;
      height: 20px;
    }
  @media (min-width: 576px) {
    background-position: center bottom;

    .item-preview-container {
    }
    .item-card {
      font-size: 24px;
      height: auto;
    }
    .slick-arrow {
      width: 40px;
      height: 40px;
      display: flex;
    }
    .slick-prev {
      left: 0px;
      display:flex;
      justify-content:flex-start;
    }
    .slick-next {
      right: 0px;
      display:flex;
      justify-content:flex-end;
    }
    .slick-arrow svg {
      width: 20px;
      height: 20px;
    }
    
  }
  @media (min-width: 768px) {
    background-position: center bottom;
    .item-preview-container {
    }
    .item-card {
      font-size: 24px;
      height: auto;
      .item-card-title {
        flex-flow: column;
        margin:10px 0px;
        height:auto;
        .profile-picture-name {
          .profile-picture {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 3px solid white;
            margin-right: 10px;
            background-color: white;
            overflow: hidden;
          }
          .profile-name {
            color: white;
            font-size: 15px;
          }
        }
      }
    }
    .slick-arrow {
      width: 30px;
      height: 30px;
      display: flex;
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-arrow svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (min-width: 768px) {
    background-position: center bottom;
    .pitch-panel-header {
      max-width:40vw;
    }
  }
  @media (min-width: 950px) {
    background-position: center bottom;
    .pitch-carousel-header {
      max-width:40vw;
    }
  }
  @media (min-width: 1200px) {
    background-position: center bottom;
    .bottom-shadow {
      background: linear-gradient(0deg, rgba(22,22,22,1) 0%, rgba(22,22,22,1) 2%, rgba(22,22,22,0) 50%, rgba(22,22,22,0) 100%);
    }
    .top-shadow {
      background: linear-gradient(180deg, rgba(22,22,22,1) 0%, rgba(22,22,22,1) 2%, rgba(22,22,22,0) 40%, rgba(22,22,22,0) 100%);
    }
    .item-preview-container {
    }
    .pitch-get-discovered {
      top:20px;
      right:20px;
      left: unset;
      bottom: unset;
    }
    .pitch-carousel-header {
      display: flex;
      flex-flow: column;
      max-width: 40vw;
      position:relative;
      margin: 20px 0px 20px 70px;
      h3 {
        justify-content:flex-start;
        align-items:center;
        display:flex;
        flex-flow:row nowrap;
        font-weight: 800;
        margin-bottom: 0;
        font-size: 30px;
        letter-spacing: 0.075rem !important;
        font-family: "MuliExtraBold";
        a {
          font-size:13px;
          display:none;
          margin-left:20px;
          font-weight:normal;
          white-space: nowrap;
          text-transform: uppercase;
          color:#19acf7;
          svg {
            margin-left: 10px;
            display: flex;
          }
        }
      }
      p {
        font-size:15px;
        font-weight: normal;
      }
    }
    .slick-slide {
    }
    .slick-current {
      opacity: 1;
    }
    .item-card {
      height: auto;
      .item-card-text {
        font-size:14px;
      }
      .item-card-title {
        margin:10px 0px;
        height:auto;
        flex-flow: row;
      }
    }
    .item-video {
    }
    .item-preview {
    }
    .pitch-carousel-header {
    }
    .item-card {
      font-size: 24px;
    }
    .slick-arrow {
      width: 54px;
      display: flex;
    }
    .slick-prev {
    }
    .slick-next {
    }
    .slick-arrow svg {
      width: 35px;
      height: 35px;
    }
  }
  @media (min-width: 1920px) {
    background-position: center center;
    .item-preview-container {
    }
    .pitch-carousel-header {
      display: flex;
      flex-flow: column;
      max-width: 30vw;
      position:relative;
      margin: 20px 70px;
      h3 {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        font-weight: 800;
        margin-bottom: 0;
        font-size: 32px;
        letter-spacing: 0.075rem !important;
        font-family: "MuliExtraBold";
        a {
          font-size:13px;
          display:none;
          align-items:center;
          margin-left:20px;
          font-weight:normal;
          white-space: nowrap;
          text-transform: uppercase;
          color:#19acf7;
        }
      }
      p {
        font-size:18px;
        font-weight: 700;
      }
    }
    .item-card {
      font-size: 24px;
    }
    .slick-arrow {
      width: 68px;
      display: flex;
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-arrow svg {
      width: 50px;
      height: 50px;
    }
  }
}
